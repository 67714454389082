<template>
  <div class="md-layout-item">
    <md-card
      :class="
        isSuperAdmin ? 'inquiry-list-no-hover__card' : 'inquiry-list__card'
      "
    >
      <div class="header">
        <div class="md-layout paddings">
          <div
            class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100"
          >
            <div class="assignment-type">
              {{ assignmentDetails.assignmentType }}
            </div>
          </div>
          <div
            class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100"
          >
            {{ assignmentDetails.inquiry.style_number }}
          </div>
          <div
            class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100"
          >
            <truncate-hovering
              :text="assignmentDetails.inquiry.style_name"
              :length="25"
            />
          </div>
          <div style="display: flex">
            <div
              class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100 mr-1"
            >
              {{ assignmentDetails.verified ? "Verified" : "Unverified" }}
            </div>
            <div @click.prevent="toggleVerificationStatus">
              <v-switch
                :key="`${assignment.id}-${assignmentDetails.rerenderToggleValue}`"
                class="verification-btn"
                :input-value="assignmentDetails.verified"
                :color="isSuperAdmin ? '#70D0CE' : 'success'"
              ></v-switch>
            </div>
          </div>
        </div>
      </div>

      <md-card-content v-if="isSuperAdmin" class="card-content">
        <div class="md-layout md-gutter" style="padding: 0px 10px">
          <div
            class="md-layout-item md-size-15 md-xsmall-size-100 seperator card-content-item"
          >
            <div class="inquiry-quantity mt-1">
              <div class="inquiry-category inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Assigned to: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.assignmentName
                }}</span>
              </div>
              <div class="inquiry-category inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Assigned on: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.assignmentType
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="md-layout-item md-size-25 md-xsmall-size-100 seperator card-content-item"
          >
            <div class="inquiry-category inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Brand: </span>
              <span class="inquiry-quantity__value">{{
                assignmentDetails.brand.name
              }}</span>
            </div>
            <div class="inquiry-category inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Supplier: </span>
              <span class="inquiry-quantity__value">{{
                assignmentDetails.supplier.name
              }}</span>
            </div>
            <div class="inquiry-material inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Factory: </span>
              <span class="inquiry-quantity__value">
                {{ assignmentDetails.factory?.name }}
              </span>
            </div>
          </div>
          <div
            class="md-layout-item md-size-25 md-xsmall-size-100 seperator card-content-item"
          >
            <div v-if="assignmentDetails.supplier.account">
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Supplier contact name: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.supplier.account.account_manager.full_name
                }}</span>
              </div>
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Supplier contact email: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.supplier.account.account_manager.email
                }}</span>
              </div>
            </div>
            <div v-if="assignmentDetails.brand.account">
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Brand contact name: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.brand.account.account_manager.full_name
                }}</span>
              </div>
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Brand contact email: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.brand.account.account_manager.email
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="md-layout-item md-size-30 md-xsmall-size-100 card-content-item"
          >
            <div v-if="assignmentDetails.creator.account">
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title"
                  >Style creator contact name:
                </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.creator.account.account_manager.full_name
                }}</span>
              </div>
              <div class="inquiry-quantity mt-1">
                <span class="inquiry-sub-title"
                  >Style creator contact email:
                </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.creator.account.account_manager.email
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-content
        v-else
        class="card-content"
        style="border: 1px solid #6a7895"
      >
        <div class="md-layout md-gutter" style="padding: 0px 10px">
          <div
            class="md-layout-item md-size-45 md-xsmall-size-100 seperator card-content-item"
          >
            <div class="inquiry-quantity mt-1">
              <div class="inquiry-category inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Assigned to: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.assignmentName
                }}</span>
              </div>
              <div class="inquiry-category inquiry-quantity mt-1">
                <span class="inquiry-sub-title">Assigned on: </span>
                <span class="inquiry-quantity__value">{{
                  assignmentDetails.assignmentType
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="md-layout-item md-size-45 md-xsmall-size-100 card-content-item"
          >
            <div class="inquiry-category inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Brand: </span>
              <span class="inquiry-quantity__value">{{
                assignmentDetails.brand.name
              }}</span>
            </div>
            <div class="inquiry-category inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Supplier: </span>
              <span class="inquiry-quantity__value">{{
                assignmentDetails.supplier.name
              }}</span>
            </div>
            <div class="inquiry-material inquiry-quantity mt-1">
              <span class="inquiry-sub-title">Factory: </span>
              <span class="inquiry-quantity__value">
                {{ assignmentDetails.factory?.name }}
              </span>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import auth from "../../auth";
export default {
  name: "VerifyAssignmentItem",
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: false,
    },
    toggleRerender: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    toggleVerificationStatus() {
      this.$emit("toggle-verification", this.assignmentDetails);
    },
  },
  computed: {
    assignmentDetails() {
      const isSupplier =
        auth.user.account?.organization_registry_id ===
        this.assignment.supplier.id;
      const isBrand =
        auth.user.account?.organization_registry_id ===
          this.assignment.bom_item?.inquiry.collection.brand_organization.id ||
        auth.user.account?.organization_registry_id ===
          this.assignment.inquiry?.collection.brand_organization.id;
      return {
        id: this.assignment.id,
        assignmentName:
          this.assignment.bom_item?.name || this.assignment.trace?.display_name,
        assignmentType: this.assignment.bom_item?.name ? "BOM" : "Digital Product Passport",
        ...(this.isSuperAdmin || (isSupplier && isBrand)
          ? {}
          : {
              otherParty: {
                ...(isSupplier
                  ? this.assignment.bom_item?.inquiry.collection
                      .brand_organization ||
                    this.assignment.inquiry?.collection.brand_organization
                  : this.assignment.supplier),
                type: isSupplier ? "Brand" : "Supplier",
              },
            }),
        brand:
          this.assignment.bom_item?.inquiry.collection.brand_organization ||
          this.assignment.inquiry?.collection.brand_organization,
        supplier: this.assignment.supplier,
        creator:
          this.assignment.bom_item?.inquiry.created_by_organization ||
          this.assignment.inquiry?.created_by_organization,
        inquiry: this.assignment.bom_item?.inquiry || this.assignment.inquiry,
        factory: this.assignment.factory,
        verified: this.isSuperAdmin
          ? this.assignment.verified_by_factory &&
            this.assignment.verified_by_brand
          : isSupplier
          ? this.assignment.verified_by_factory
          : this.assignment.verified_by_brand,
        rerenderToggleValue: this.toggleRerender,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  overflow-wrap: anywhere;
}
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}
.assignment-type {
  font-size: 12px;
  color: #ffffff;
  background-color: #473068;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
}
.inquiry-list__card:hover {
  background: #ffffff;
  box-shadow: 0px 17px 6px rgba(28, 45, 71, 0.15);
  border-radius: 7px;
  .header {
    background: #70d0ce;
    color: #473068;
  }
  .inquiry-style-number {
    color: #473068;
  }
  .inquiry-product-type {
    color: #473068;
  }
  .date {
    color: #473068;
  }
}
.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.style-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.style-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.style-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
.inquiry-list {
  .md-title,
  .md-subhead {
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
  }

  .inquiry-quantity {
    text-align: left;
  }

  .inquiry-colors {
    text-align: left;
    padding-top: 5px;
  }

  .inquiry-quantity__value {
    font-size: 15px;
    font-weight: bold;
    color: #442d65;
  }

  .inquiry-sub-title {
    font-size: 15px;
    color: #442d65;
  }

  .md-card-actions.md-alignment-right {
    justify-content: space-between;
  }
}

.grid-2 {
  display: flex;
  flex-direction: row;
}

.inquiry-search-bar {
  text-align: right;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search-content {
  margin-bottom: 40px;
  padding: 12px;
}

.search_field_class {
  margin: 0%;
}

.inquiry-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.header {
  background: #6a7895;
  height: calc(100% - 50%);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.inquiry-product-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.paddings {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.card-content {
  padding-bottom: 16px !important;
}

.embed {
  height: 50px;
}

.seperator {
  border-right: 1px solid #78909c;
}

.alignment {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .seperator {
    border-right: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .seperator {
    border-right: none;
    border-right: 10px solid #78909c;
  }
}

.card-content-item {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.centered-and-cropped {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.inquiry-list__card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.inquiry-list__card.md-card.md-theme-default:hover {
  text-decoration: none;
  background-color: #f3f1f1;
}

.inquiry-list-no-hover__card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65;
}

.color_class {
  color: #492a67 !important;
}

.qty_color {
  background-color: #c6a8ec !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #473068 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #473068 !important;
}

.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}

.search_type_container {
  margin-top: -10px !important;
  background-color: #f3edf7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.md-select-menu {
  top: 100px;
  border-radius: 64px;
  border: 10px solid;
}

.list_color.md-theme-default.md-selected {
  background-color: #e2daed !important;
}

.img-container {
  align-items: center;
}

.verification-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #64d5ca;
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
}

.verification-btn::v-deep {
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}
.v-input--switch {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
